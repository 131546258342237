










































































































































































import { Component, Vue } from 'vue-property-decorator';
import UserAvatar from "@/components/user/UserAvatar.vue";
import Roles from "@/roles";
import gql from "graphql-tag";
import {RemoveWorkspaceUsers, WorkspaceQuery} from "@/graphql/workspace";
import TransferPrimaryOwnership from "@/components/settings/workspace/TransferPrimaryOwnership.vue";
import CreateWorkspaceInviteModal from "@/components/settings/workspace/CreateWorkspaceInviteModal.vue";
import Template from "@/components/workspace/Template.vue";
import PresenceContainer from "@/components/workspace/PresenceContainer.vue";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";
import {UpdateSubscription} from "@/graphql/WorkspaceSubscription";
import Paywall from "@/components/subscription/Paywall.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";
import UserProfileDetails from "@/components/user/UserProfileDetails.vue";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";

@Component({
  components: {WorkspacePage, PresenceContainer, Template, UserAvatar},
})
export default class MembersManager extends Vue {
  filterActive: boolean = false;
  searchInput: string | null = null;
  roleSelected: string = 'all';
  showActions: boolean = true;
  editMode: boolean = true;

  mounted() {
    document.title = "Pembio | Members";
    this.$gtag.event('pmb_app_mount_members_manage_page');
  }

  destroyed() {
    document.title = "Pembio";
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get filteredWorkspaceMembers() {
    let members = this.workspace.allMembers.filter((member: any)=> {
      return member.user.deleted_at === null
    });

    if(this.searchInput){
      members = members
        .filter((mem: any) => {
          return mem.user.first_name.toLowerCase().includes(this.searchInput?.toLowerCase()) ||
          mem.user.last_name.toLowerCase().includes(this.searchInput?.toLowerCase()) ||
          mem.user.email.toLowerCase().includes(this.searchInput?.toLowerCase())
        })
    }

    if(this.roleSelected != 'all') {
      members = members
        .filter((mem: any) => {
          return mem.role === this.roleSelected;
        })
    }
    return members;
  }

  get filterDeletedWorkspaceMember() {
    if(this.workspace.members) {
      return this.workspace.members.filter((member: any) => {
        return member.user.deleted_at === null;
      })
    }
    return null;
  }

  get me() {
    return this.$store.state.me;
  }

  roleName(role: string) {
    return Roles.formateRole(role);
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  requestTransferOwnershipModal() {
    this.$buefy.modal.open({
      component: TransferPrimaryOwnership,
      parent: this,
      width: 500,
    });
  }

  openUserInviteModal() {
    
    this.$buefy.modal.open({
      component: CreateWorkspaceInviteModal,
      props: {
        workspaceId: this.workspace!.id,
        workspacePid: this.workspace!.pid,
      },
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
      width: 500,
    });


  }

  deleteInvite(id: string) {
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation DeleteWorkspaceInvite($id: ID!) {
                        deleteWorkspaceInvite(id: $id) {
                            id
                        }
                    }
                `,
        refetchQueries: [
          {query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}},
        ],
        variables: {
          id: id,
        },
      })
      .then(() => {
        //
      })
      .catch((_error) => {
      
      });
  }

  setRole(user_id: string, role: string) {
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation SetWorkspaceRole($workspace_id: ID!, $user_id: ID!, $role: Role!) {
                        setWorkspaceRole(workspace_id: $workspace_id, user_id: $user_id, role: $role) {
                            role
                        }
                    }
                `,
        variables: {
          user_id: user_id,
          workspace_id: this.workspace!.id,
          role: role,
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.workspace!.pid } }],
      })
      .finally(() => {
        this.$buefy.toast.open({
          message: 'User role updated!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });
  }

  get checkWorkspaceSubscription() {

    if(this.workspace.currentSubscription && this.filterDeletedWorkspaceMember.length < this.workspace.currentSubscription.plan.planAccess
      .max_user) {
   
      return true;
    }else if(!this.workspace.currentSubscription && this.filterDeletedWorkspaceMember.length < this.$store.state.plans[0].planAccess.max_user) {
   
      return true;
    }
    return false;
  }

  upgradePlan() {
    this.$buefy.modal.open({
      component: Paywall,
      width: '480px',
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  canSubscribe() {
    if(this.workspace.currentSubscription.subscribed_quantity >= this.filterDeletedWorkspaceMember.length + 1) {
      return false;
    }
    return true;
  }

  setWorkspaceAccess(user_id: string, access: any) {
    
    if(this.workspace.currentSubscription && this.workspace.currentSubscription.subscription_id) {
      this.$apollo.mutate({
        mutation: UpdateSubscription,
        variables: {
          subscription_id: this.workspace.currentSubscription.subscription_id,
          stripe_plan_id: this.workspace.currentSubscription.plan.stripe_plan_id,
          itemQuantity: (access == 0) ? this.filterDeletedWorkspaceMember.length + 1 : this.filterDeletedWorkspaceMember.length - 1,
          prorationBehavior: 'always_invoice'
        },
      }).then((result: any) => {
        if(result.data.updateSubscription.subscription_id){
          this.updateTheWorkspaceAccess(user_id, access)
        }
      })
    }else{
      this.updateTheWorkspaceAccess(user_id, access);
    }
   
  }

  openProfileDetails(userId: any) {
    
    if (userId === this.me.id) {
      this.$buefy.modal.open({
        component: UserProfile,
        // @ts-ignore
        customClass: 'is-paddingless user_profile_modal',
        width: '700px',
        parent: this,
      });
    } else {
      //this.$events.fire('refetch-workspace');
      this.$buefy.modal.open({
        component: UserProfileDetails,
        // @ts-ignore
        customClass: 'is-paddingless other_user_profile_modal',
        props: {userId: userId},
        width: '520px',
        parent: this,
      });
    }
    
  }

  updateTheWorkspaceAccess(user_id: string, access: any) {
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation SetWorkspaceAccess($workspace_id: ID!, $user_id: ID!, $access_workspace: String!) {
                        setWorkspaceAccess(workspace_id: $workspace_id, user_id: $user_id, access_workspace: $access_workspace) {
                            id
                        }
                    }
                `,
        variables: {
          user_id: user_id,
          workspace_id: this.workspace!.id,
          access_workspace: access,
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.workspace!.pid } }],
      })
      .finally(() => {
        if(access == 0) {
          this.$buefy.toast.open({
            message: 'User un-blocked!',
            position: 'is-bottom-right',
            type: 'is-black',
          });
        }else{
          this.$buefy.toast.open({
            message: 'User blocked!',
            position: 'is-bottom-right',
            type: 'is-black',
          });
        }

      });
  }

  confirmRemoveMember(user_id: string) {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: "Are you sure you want to remove this user ? "
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if(value === true) {
            this.removeFromWorkspace(user_id);
          }
        }
      },
      width: '500px',
    });
  }

  removeFromWorkspace(user_id: string) {
    this.$apollo.mutate({
      mutation: RemoveWorkspaceUsers,
      variables: {
        user_id: user_id,
        workspace_id: this.workspace.id
      },
      refetchQueries: [
        {query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}},
      ],
    }).then((_res: any) => {
      this.$buefy.toast.open({
        message: 'User removed successfully!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    }).catch((_err: any) => {
    })
  }

}
