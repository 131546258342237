















































import { Component, Vue } from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import { WorkspaceQuery } from '@/graphql/workspace';

@Component({
  components: { UserAvatar },

  apollo: {
    workspace: {
      query: WorkspaceQuery,
      variables() {
        return {
          pid: this.$route.params.workspace_pid,
        };
      },
    },
  },
})
export default class TransferPrimaryOwnership extends Vue {
  get me() {
    return this.$store.state.me;
  }
}
