



















































import {Component, Vue} from 'vue-property-decorator';
import WorkspaceMemberItem from "@/components/navigation/WorkspaceMemberItem.vue";
import CreateWorkspaceInviteModal from "@/components/settings/workspace/CreateWorkspaceInviteModal.vue";

@Component({
  components: {WorkspaceMemberItem},
})
export default class PresenceContainer extends Vue {
  onlyShowOnline: boolean = false;
  preferredListCount: number = 4;

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get memberFillerCount() {
    if(this.workspaceMembersCount < this.preferredListCount) {
      return this.preferredListCount - this.workspaceMembersCount;
    } else {
      return null;
    }
  }

  openUserInviteModal() {
    if(this.isAdminOfWorkspace) {
      this.$buefy.modal.open({
        component: CreateWorkspaceInviteModal,
        props: {
          workspaceId: this.workspace!.id,
          workspacePid: this.workspace!.pid,
        },
        // @ts-ignore
        customClass: 'is-paddingless',
        parent: this,
        width: '480px',
      });
    }
  }

  get workspaceMembersCount() {
    return this.$store.state.workspace.members.length;
  }

  get avatarColumnSize() {
    //let showAmount = this.$store.state.online_user_ids.length;
    let showAmount = this.workspaceMembersCount;
    if(showAmount === 3) {
      return 'is-6'
    } else if (showAmount >= 4 && showAmount <= 5) {
      return 'is-4';
    } else if (showAmount >= 6 && showAmount <= 7) {
      return 'is-3';
    }else if (showAmount >= 8 && showAmount <= 9) {
      return 'is-one-fifth';
    } else if(showAmount >= 10) {
      return 'is-one-fifth';
    } else {
      return null
    }
  }

  goToAddMembers() {
    this.$router.push({
      name: 'members-manager',
      params:{
        workspace_pid: this.$store.state.workspace.pid
      }
    })
  }

  public isOnline(id: any): boolean {
    return this.$store.state.online_user_ids.includes(id);
  }
}
